import React, { useContext, useEffect, useState } from 'react';
import { Econtext } from '../context/Econtext';
import PortfolioStats from '../components/user/PortfolioStats';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import toast from 'react-hot-toast';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Blocks, Grid, InfinitySpin, Oval } from 'react-loader-spinner'
import Webcam from 'react-webcam';
import { Modal } from 'react-bootstrap';

import { Camera, CameraResultType } from '@capacitor/camera';






const SurveyFormPage = () => {
    const { surveyId } = useParams();
    const navigate = useNavigate();

    const { authState } = useContext(Econtext);
    const { user } = authState;


    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [surveyer, setSurveyer] = useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [stepCount, setStepCount] = useState(1);
    const [sectionName, setSectionName] = useState("")



    useEffect(() => {
        console.log("use effect runs");
        console.log(Date.now())
        loadQuestions();
    }, [])


    const loadQuestions = async () => {
        try {
            setLoading(true);
            const axiosRes = await axios({
                method: "GET",
                //headers: { 'x-access-token': localStorage.getItem('token') },
                url: `${BASE_URL}/api/v1/surveys/public/${surveyId}`,
            });
            console.log("loadQuestions [SUCCESS]", axiosRes.data.resData.questions);
            if (axiosRes.data.success) {
                setLoading(false);
                setQuestions(axiosRes.data.resData.questions)
            } else {
                setLoading(false);

            }
        } catch (err) {
            console.log("loadQuestions [ERROR]", err);
            setLoading(false);
        }
    }


    const handleChange = (e) => {
        //console.log(e.target.name, e.target.value);
        setAnswers([...answers, { qId: e.target.name, qAns: e.target.value }]);
        //setAnswers(answers.add( `${[e.target.name]}_${e.target.value}`));

    }



    const handleSurveyChange = (e) => {
        setSurveyer(e.target.value);

    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let seenKeys = new Set();
        let finalRes = [];
        const serverRes = {};

        // Loop from end to start to ensure latest occurrence is kept
        for (let i = answers.length - 1; i >= 0; i--) {
            const currentKey = answers[i]['qId'];
            if (!seenKeys.has(currentKey)) {
                seenKeys.add(currentKey);
                finalRes.push(answers[i]);
            }
        }

        // Since we looped from end to start, we need to reverse the result array
        finalRes = finalRes.reverse();
        serverRes.surveyDoneBy = surveyer;
        serverRes.surveyFormData = finalRes;
        serverRes.allPhotos = photos;
        serverRes.uniqueIdxSubmit = Date.now();
        console.log("answers submitted", serverRes);
        // for(let i = 0;i<questions.length;i++){
        //     console.log(e.target[i].name);
        // }
        //console.log(e.target[0].name);
        // console.log(e.target[0].value);
        try {
            const axiosRes = await axios({
                method: "POST",
                //headers: { 'x-access-token': localStorage.getItem('token') },
                url: `${BASE_URL}/api/v1/surveys/public/${surveyId}`,
                data: serverRes,
            });
            console.log("create [SUCCESS]", axiosRes.data.resData);
            if (axiosRes.data.success) {
                setLoading(false);
                toast.success("Success");
                navigate('/');

            } else {
                setLoading(false);
                toast.success("Failed");

            }
        } catch (err) {
            console.log("create [ERROR]", err);
            setLoading(false);
        }

    }

    const [photos, setPhotos] = useState([]);

    const videoConstraints = {
        width: 680,
        height: 720,
        facingMode: { exact: "environment" }
    }

    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 20,
            allowEditing: false,
            resultType: CameraResultType.Base64
        });

        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        var imageUrl = image.webPath;
        //console.log(image);
        let base64ImgRes = "data:image/png;base64,"+image?.base64String;
        setPhotos([...photos,base64ImgRes]);

        // Can be set to the src of an image now
        //imageElement.src = imageUrl;
    };

    const prevQues = () => {
        if (stepCount == 1) {
            toast.success("Already On Page 1");
            return;
        }
        let count = stepCount - 1;
        if (count <= questions.length) {
            setSectionName("");
        }
        setStepCount(count);
    }

    const nextQues = () => {
        let count = stepCount + 1;
        if (count > questions.length) {
            setSectionName("LAST_SECTION");
        }
        setStepCount(count);
    }

    if (loading) {
        return <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"

        />
    }



    return (
        <>
            <div className='main-sec'>
                <div className='container'>
                    <h4><b>चुनाव सर्वेक्षण प्रपत्र</b></h4>
                </div>


                <Form onSubmit={handleSubmit}>

                    {
                        questions && questions.map((singleQuestion, index) => {
                            //console.log(singleQuestion);
                            return (
                                <>
                                    {
                                        stepCount == singleQuestion?.qNo ?
                                            <>
                                                <div key={index} class="form-group">
                                                    <p>{singleQuestion?.qNo}. {" "}{singleQuestion?.qLabel}</p>
                                                    {
                                                        singleQuestion?.qType === "RATING" ?
                                                            <>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="1"
                                                                        onChange={handleChange}
                                                                    />
                                                                    1
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="2"
                                                                        onChange={handleChange}
                                                                    />
                                                                    2
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="3"
                                                                        onChange={handleChange}
                                                                    />
                                                                    3
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="4"
                                                                        onChange={handleChange}
                                                                    />
                                                                    4
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="5"
                                                                        onChange={handleChange}
                                                                    />
                                                                    5
                                                                </label>

                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    {
                                                        singleQuestion?.qType === "RADIO" ?
                                                            <>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="Male"
                                                                        onChange={handleChange}
                                                                    />
                                                                    Male
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        class="input-radio"
                                                                        type="radio"
                                                                        name={singleQuestion?._id}
                                                                        value="Female"
                                                                        onChange={handleChange}
                                                                    />
                                                                    Female
                                                                </label>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    {
                                                        singleQuestion?.qType === "ONELINE" ?
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id=""
                                                                    name={singleQuestion?._id}
                                                                    placeholder={singleQuestion?.qPlaceholder}
                                                                    onChange={handleChange}
                                                                />

                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                    {
                                                        singleQuestion?.qType === "DESC" ?
                                                            <>
                                                                <textarea
                                                                    id="textarea"
                                                                    name={singleQuestion?._id}
                                                                    placeholder={singleQuestion?.qPlaceholder}
                                                                    onChange={handleChange}
                                                                />

                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                </div>

                                            </>
                                            :
                                            <>
                                            </>


                                    }


                                </>
                            )
                        })

                    }
                    {
                        sectionName == "LAST_SECTION" &&
                        <>
                            <div>
                                {
                                    photos.map(singlePhoto => (
                                        <td> <img src={singlePhoto} alt="Locality Img" style={{ width: "60px" }} /></td>
                                    ))
                                }

                                <Button variant="primary" className='mb-3' onClick={takePicture}>
                                    बूथ की फोटो अपलोड करें
                                </Button>
                                <input
                                    type="text"
                                    id=""
                                    name="surveyDone"
                                    placeholder="Enter Surveyer Name"
                                    onChange={handleSurveyChange}
                                />
                            </div>

                            <div class="form-group mt-2">

                                <Button variant="success" type="submit">
                                    Submit
                                </Button>

                            </div>
                        </>
                    }

                </Form>

                <button className='prev' onClick={prevQues}>पीछे जाये</button>
                <button className='next' onClick={nextQues}>आगे बढ़ें</button>



                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Capture Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Webcam
                            audio={false}
                            height={480}
                            screenshotFormat="image/jpeg"
                            width={480}
                            videoConstraints={videoConstraints}
                        >
                            {({ getScreenshot }) => (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        const imageSrc = getScreenshot();
                                        setPhotos([...photos, imageSrc]);
                                        console.log(photos);
                                        handleClose();
                                    }}
                                >
                                    Capture photo
                                </Button>
                            )}
                        </Webcam>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>

                <div>

                </div>
            </div>
        </>

    )
}

export default SurveyFormPage;
import React, { useEffect, useContext, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Blocks } from 'react-loader-spinner';
import { BASE_URL } from '../../config';

const UserProfile = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);



  useEffect(() => {
    loadSurveys();
  }, [])


  const loadSurveys = async () => {
    try {
      setLoading(true);
      const axiosRes = await axios({
        method: "GET",
        //headers: { 'x-access-token': localStorage.getItem('token') },
        url: `${BASE_URL}/api/v1/surveys`,
      });
      console.log("loadSurveys [SUCCESS]", axiosRes.data.resData.allSurveys);
      if (axiosRes.data.success) {
        setLoading(false);
        setSurveys(axiosRes.data.resData.allSurveys)
      } else {
        setLoading(false);

      }
    } catch (err) {
      console.log("loadSurveys [ERROR]", err);
      setLoading(false);
    }
  }


  if (loading) {
    return <Blocks
      visible={true}
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"

    />
  }

  return (
    <section>

      <h2 style={{ color: "black" }}>Active Surveys</h2>

      {surveys && surveys.map((singleSurvey,idx) => (
        <>
          <Link to={`/dashboard/${singleSurvey._id}`} className='btn btn-primary'>
            {singleSurvey?.surveyName}
          </Link>
        </>

      ))
      }
    </section>
  )
}

export default UserProfile
import './App.css';
import React, { useEffect, useContext, useState } from "react";
import AuthLayout from './layout/AuthLayout'
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserLayout from './layout/UserLayout';
import UserProfile from './pages/user/UserProfile';
import NotFound from './pages/NotFound';
import HomePage from './pages/HomePage';
import SingleTender from './pages/SingleTender';


import { Toaster } from 'react-hot-toast';

//Econtext files
import { Econtext } from "./context/Econtext";
import Settings from './pages/user/Settings';
import AllPortfolio from './pages/user/AllPortfolio';
import SurveyFormPage from './pages/SurveyFormPage';
import Dashboard from './pages/Dashboard';


const App = () => {

  const {
    authState,
    authDispatch,
    login,
    logout,
  } = useContext(Econtext);
  const { user, ustate, isLoggedIn } = authState;

  useEffect(() => {
    console.log("current auth state", authState);
    // authDispatch({ type: "AUTH_ERROR", payload: "Message changed" });
    // console.log("after auth state", ustate);
  }, [])


  return (
    <>
      {/* {ustate} */}
      <Toaster />

      <Routes>

        <Route path="" element={<UserLayout />}>
        <Route path="/" element={<HomePage />} />
          <Route path="/survey/:surveyId" element={<SurveyFormPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<UserProfile />} />
          <Route path="/dashboard/:surveyId" element={<Dashboard />} />
        </Route>
        <Route path='*' element={<NotFound />} />



      </Routes>
    </>
  );
}

export default App;

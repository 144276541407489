//Backend URL:

//local
//export const BASE_URL = "http://127.0.0.1:8484";

//Stagging
//export const BASE_URL = "http://109.199.103.4:8484";

//Live
export const BASE_URL = "https://api-survey.tejtech.in.net";
// export const BASE_URL = "https://tejtech.com";

import React, { useEffect, useContext, useState } from 'react';
import { Econtext } from "../context/Econtext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';
import { Blocks } from 'react-loader-spinner';
import { Table } from 'react-bootstrap';




const Dashboard = () => {
    const { surveyId } = useParams();
    const navigate = useNavigate();

    const query = new URLSearchParams(window.location.search);
    // console.log(surveyId)//123

    const {
        authState,
        authDispatch,
        login,
        logout,
    } = useContext(Econtext);
    const { user, ustate, isLoggedIn } = authState;

    const [loading, setLoading] = useState(true);

    const [questions, setQuestions] = useState([]);
    const [answerSet, setAnswerSet] = useState();


    useEffect(() => {
        loadUser();
        loadDashboard();
    }, [])

    const loadUser = async () => {
        try {
            let token = localStorage.getItem('token');
            if (!token) {
                console.log("No token found logging out");
                authDispatch({ type: "LOGOUT" });
                navigate('/login');
                return false;
            }

            const axiosRes = await axios({
                method: "GET",
                headers: { 'x-access-token': localStorage.getItem('token') },
                url: `${BASE_URL}/api/v1/users/getme`,
            });
            console.log(axiosRes.data);
            if (axiosRes.data) {
                console.log("loadUser [SUCCESS]", axiosRes.data);
                authDispatch({ type: "USER_LOADED", payload: axiosRes.data.resData });

            } else {
                authDispatch({ type: "LOGOUT" });
                navigate('/login');
            }
            return axiosRes?.data;
        } catch (err) {
            console.log("loadUser [ERROR]", err);
            authDispatch({ type: "LOGOUT" });
            navigate('/login');
        }
    }

    const logoutUser = () => {
        logout();
        navigate('/');

    }




    const loadDashboard = async () => {
        try {
            setLoading(true);
            const axiosRes = await axios({
                method: "GET",
                headers: { 'x-access-token': localStorage.getItem('token') },
                url: `${BASE_URL}/api/v1/surveys/single/${surveyId}`,
            });
            console.log("loadDashboard [SUCCESS]", axiosRes.data.resData);
            if (axiosRes.data.success) {
                setLoading(false);
                setQuestions(axiosRes.data.resData.allQuestions);
                setAnswerSet(axiosRes.data.resData.allAnswerSet);
            } else {
                setLoading(false);

            }
        } catch (err) {
            console.log("loadDashboard [ERROR]", err);
            setLoading(false);
        }
    }

    if (loading) {
        return <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"

        />
    }


    return (
        <>
            <section>
                <button className='btn btn-grey' onClick={logoutUser}>Logout</button>
                <h1 style={{ color: "black" }}>Dashboard</h1>



                <Table>
                    <thead>
                        <tr>
                            <th>Survey Done By</th>
                            {questions && questions.map(singleQuestion => (
                                <>
                                    <th>{singleQuestion?.qLabel} </th>
                                </>
                            ))
                            }
                            <th>Images Captured</th>

                        </tr>
                    </thead>
                    <tbody>

                        {answerSet && answerSet.map((singleAnswerSet, rowIndex) => (
                            <>
                                <tr>
                                    <td>{singleAnswerSet.answerSet[0]?.surveyDoneBy}</td>
                                    {
                                        singleAnswerSet.answerSet.map(singleAnswer => (
                                            <td>{singleAnswer?.ansValue}</td>
                                        ))
                                    }
                                    {
                                        singleAnswerSet.photos.map(singlePhoto => (
                                            <td> <img src={singlePhoto?.imgStr} alt="Locality Img" style={{ width: "60px" }} /></td>
                                        ))
                                    }

                                </tr>
                            </>

                        ))}


                    </tbody>
                </Table>


            </section >

        </>

    )
}

export default Dashboard
import React, { useContext, useEffect, useState } from 'react';
import { Econtext } from '../context/Econtext';
import PortfolioStats from '../components/user/PortfolioStats';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import toast from 'react-hot-toast';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';




const HomePage = () => {
  const { authState } = useContext(Econtext);
  const { user } = authState;


  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    loadSurveys();
  }, [])


  const loadSurveys = async () => {
    try {
      setLoading(true);
      const axiosRes = await axios({
        method: "GET",
        //headers: { 'x-access-token': localStorage.getItem('token') },
        url: `${BASE_URL}/api/v1/surveys`,
      });
      console.log("loadSurveys [SUCCESS]", axiosRes.data.resData.allSurveys);
      if (axiosRes.data.success) {
        setLoading(false);
        setSurveys(axiosRes.data.resData.allSurveys)
      } else {
        setLoading(false);

      }
    } catch (err) {
      console.log("loadSurveys [ERROR]", err);
      setLoading(false);
    }
  }


  return (


    <>

      <div className='text-center' style={{ margin: "5rem auto" }}>
        <h3 className='text-dark'><b>Welcome to Election Survey</b></h3>
        <img src={'./survey.png'} style={{ width: "100%" }} />

        <div className='p-3'>
          {surveys && surveys.map(singleSurvey => (
            <Link to={`/survey/${singleSurvey._id}`} className='btn btn-primary'>
              Start Survey
            </Link>
          ))

          }
          
          <br></br>
          <Link to="/login" className='btn btn-danger mt-3'>Admin Login</Link>
        </div>
      </div>
    </>

  )
}

export default HomePage;